import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { Components } from "@ionic/core";
import { ReportsService as reportService } from '../reports/reports.service';
import { ColumnMode, DatatableComponent, SelectionType} from "@swimlane/ngx-datatable";
import { StaffService as staffService } from "../staff/staff.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
@Component({
  selector: 'available-reports',
  templateUrl: './available-reports.component.html',
  styleUrls: ['./available-reports.component.scss'],
})
export class AvailableReportsComponent implements OnInit {
  availableReports: any[] = [];
  staffId:any;
  // pageSize = 5; // Define the page size
allReports = []; // Full list of reports
  public reportForm: FormGroup;
  loading = false;
  @Input() create;
  //yearList = new Date().getFullYear();
  Reports: any;
  @Input() modal: Components.IonModal;
  isConfirm = false;
  @Input() isCancel;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(private reportService: reportService,
    private staffService:staffService
  ) { 

    
  }
  get profile() {
    return JSON.parse(
      window.localStorage.getItem("_ionicstorage/_ionickv/userSubject")
    );
  }
  
  getstaffidbyname(){
    this.staffService.getStaffInfoByLoginName(this.profile.name).subscribe(
      (res: any) => {
        console.log("This is the response from staff id",res);
      this.staffId=res;
        console.log("This is the response from staff id",res);
      },
      (error: any) => {
        console.error('Error fetching staff info:', error);
      }
    );
  }
  onComplete(row){
    console.log("This is row",row);
    var reportId= row.id;
    const reportObject = JSON.parse(row.reportObject);
    var staffAgency= reportObject.StaffAgency;
    var staffSchool=reportObject.StaffSchool;
    var startDate=reportObject.StartDate;
    var endDate=reportObject.EndDate;

    this.reportService
        .getBulkAttendanceReport(reportId,staffAgency, staffSchool, startDate,endDate)
        .subscribe((response: any) => {
          window.open(response.download);
        });
  }
  fetchData() {
    this.loading = true;
    this.getstaffidbyname()
    this.reportService.getAttendanceAuditReports().subscribe((data: any) => {
      console.log("fetchData(): data from service:", data);
      this.availableReports = data.filter((report: any) => report.staffId === this.staffId.staff[0].staff.id);
      this.loading = false; 
    });
  }

  ngOnInit() {
    this.fetchData()
    this.getstaffidbyname()
  }

}

// export class AvailableReportsComponent implements OnInit {
//   allReports: any[] = []; // Full list of reports
//   displayedReports: any[] = []; // Subset of reports to display on the current page
//   pageSize = 10; // Number of items per page
//   totalRecords = 0; // Total number of reports
//   loading = false;

//   constructor(private reportService: reportService) {}

//   ngOnInit() {
//     this.fetchData();
//   }

//   fetchData() {
//     this.loading = true;
//     this.reportService.getAttendanceAuditReports().subscribe((data: any) => {
//       console.log("fetchData(): data from service:", data);
//       this.allReports = data; // Load all reports
//       this.totalRecords = this.allReports.length; // Update total record count
//       this.updateDisplayedReports(0); // Initialize the first page
//       this.loading = false;
//     });
//   }

//   onPageChange(event: any) {
//     const start = event.offset * this.pageSize;
//     this.updateDisplayedReports(start);
//   }

//   updateDisplayedReports(startIndex: number) {
//     const endIndex = startIndex + this.pageSize;
//     this.displayedReports = this.allReports.slice(startIndex, endIndex);
//   }
// }
