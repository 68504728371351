// used in award details, generate report modal, master calendar, reports page, update award amt modal
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { Observable, from } from 'rxjs';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {


  public idToken: string;
  public S3Reports:Array<any> = [];
  public currentMasterCalendar: any;

  constructor(public httpClient: HttpClient, public storage: Storage, public toastController: ToastController) { }

  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      'charset': 'UTF-8'
    });

  // get reports list
  getReports() {
    console.log('getReports()'); // todo: comment out logging as needed for prod
    return this.loadReports().pipe(map(this.processReports, this));
  }
  // get reports list
  private loadReports() {
    console.log('loadReports() with url: ', environment.reportsUrl);
    return this.httpClient.get(environment.reportsUrl);
  }
  // fetch fiscal years data
  getFiscalYears() {
    console.log('getFiscalYears()'); // todo: comment out logging as needed for prod
    return this.loadFiscalYears();
  }
  private loadFiscalYears() {
    const fiscal = from(this.storage.get('fiscalyears').then(res => {
      var fiscalYears = res;
      if(fiscalYears == undefined || fiscalYears == null){
        console.log('loadFiscalYears() with url: ', environment.fiscalYearUrl);
        this.httpClient.get(environment.fiscalYearUrl).pipe(map(this.processFiscalYear, this)).subscribe((data) => {
          this.storage.set('fiscalyears', data);
          return data;
        });
      }else{
        return fiscalYears;
      }
    }));
    return fiscal;

    console.log('loadFiscalYears() with url: ', environment.fiscalYearUrl);
    return this.httpClient.get(environment.fiscalYearUrl).pipe();
  }
  processFiscalYear(data: any) {
    console.log('processFiscalYear() with', data, 'rows.');
    return data;
  }
  // fetch calendar years data
  getCalendarYears() {
    console.log('getCalendarYears()'); // todo: comment out logging as needed for prod
    return this.loadCalendarYears();
  }

  private loadCalendarYears() {
    const calendar = from(this.storage.get('calendaryears').then(res => {
      var calendarYears = res;
      if(calendarYears == undefined || calendarYears == null){
        console.log('loadCalendarYears() with url: ', environment.calendarYearUrl);
        this.httpClient.get(environment.calendarYearUrl).pipe(map(this.processCalendarYear, this)).subscribe((data) => {
          this.storage.set('calendaryears', data);
          return data;
        });
      }else{
        return calendarYears;
      }
    }));
    return calendar;
  }

  setCurrentMasterCalendar(){
    return this.getMasterCalendars().pipe(map(this.assignCurrentMasterCalendar, this));
  }

  getCurrentMasterCalendar(){
    return this.currentMasterCalendar;
  }

  assignCurrentMasterCalendar(data: any) {
    if(data != undefined){
      var currentActiveMasterCalendar = data.find(m => m.isCurrent);
      if(currentActiveMasterCalendar != undefined){
        this.currentMasterCalendar = currentActiveMasterCalendar;
      }
    }
    return data;
  }

  getMasterCalendars() {
    console.log('getMasterCalendars()');
    return this.loadMasterCalendars();
  }
  getMasterCalendarsForReports() {
    console.log('getMasterCalendarsForReports()');
    return this.loadMasterCalendarsForReports();
  }

  private loadMasterCalendars() {
    console.log('loadMasterCalendars() with url: ', environment.calendarYearUrl + 'GetAllMasterCalendars');
    return this.httpClient.get(environment.calendarYearUrl + 'GetAllMasterCalendars');
  }

  private loadMasterCalendarsForReports() {
    console.log('loadMasterCalendars() with url: ', environment.calendarYearUrl + 'GetAllMasterCalendarsForReports');
    return this.httpClient.get(environment.calendarYearUrl + 'GetAllMasterCalendarsForReports');
  }

   loadAdminMasterCalendars() {
    console.log('loadMasterCalendars() with url: ', environment.calendarYearUrl + 'GetAllAdminCalendarYears');
    return this.httpClient.get(environment.calendarYearUrl + 'GetAllAdminCalendarYears');
  }
  processCalendarYear(data: any) {
    console.log('processCalendarYear() with', data, 'rows.');
    return data;
  }

  updateCurrentMasterCalendar(masterCalendarName) {
    console.log('updateMasterCalendar() with url: ', environment.calendarYearUrl + 'UpdateCurrentMasterCalendar');
    // prevents user from submitting new activity without a name or enrichment code inputted

      return this.httpClient.post( environment.calendarYearUrl + 'UpdateCurrentMasterCalendar/' + masterCalendarName, {}).subscribe(
        result => {
          this.showSuccessToast('Updated active master calendar to ' + masterCalendarName);
          this.setCurrentMasterCalendar().subscribe(res =>
          {
          });
        },
        error => {
          this.showError(error, null);
        },
      );
  }


  // generate excel report
  generateReport(): Observable<any> {
    console.log('generateReport() with url: ', environment.reportsUrl);
    return this.httpClient.get(environment.reportsUrl + 'generatestudentreport',
      { responseType: 'blob' });
    // return this.httpClient.get(environment.reportsUrl + 'generatestudentreport',
    // { headers: this.httpOptions, responseType: 'blob' });
  }
  async showError(error, message) {
    if (message === null) {
      message = 'Error!';
    }
    console.log(error);
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: message,
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }

  async showSuccessToast(message) {
    if (message === null) {
      message = 'Success!';
    }
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: message,
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }

  // generate excel report
  generateGlobalReport(requestReport): Observable<any> {
    console.log('generateReport() with url: ', environment.reportsUrl);

    // tslint:disable-next-line: max-line-length
    // return this.httpClient.get(environment.fiscalyearsUrl,
    // { params: requestReportModel });
    return this.httpClient.post(environment.reportsUrl + 'generatereport',
      requestReport, { responseType: 'arraybuffer' });
  }

    // generate excel report
    submitReportRequest(requestReport,studentsType): Observable<any> {
        requestReport.studentsType=studentsType;
        console.log('submitReportRequest() with url: ', environment.reportsUrl + 'submitreportrequest');
        console.log(requestReport)
        return this.httpClient.post(environment.reportsUrl + 'submitreportrequest',
          requestReport);
    }
  

    reportRequestComplete(reportname): Observable<any> {
      console.log('reportRequestComplete() with url: ', environment.reportsUrl + 'reportrequestcomplete');
      return this.httpClient.get(environment.reportsUrl + 'reportrequestcomplete/' + reportname);
    }
    // generate excel report
    downloadReport(requestUrl): Observable<any> {
      console.log('downloadReport() with url: ', requestUrl);
      return this.httpClient.get(requestUrl);
    }

  // generate excel report
  // updateReport(id) {
  //   console.log('generateReport() with url: ', environment.reportsUrl);
  //   return this.httpClient.put(environment.reportsUrl + 'updategenerateddate/' + id).subscribe(response => console.log(response));
  // }

  processReports(data: any) {
    console.log('processReports() with', data.length, 'rows.');
    return data;
  }

  getS3ReportUrl(reportId){
    return this.S3Reports[reportId]
  }
  
  getAttendanceReport(agency,school,date){
    const url = `${environment.reportsUrl}attendance-report/${agency}/${school}?date=${date}`
    console.log("Attendance report URL: ",url);
    return this.httpClient.get(url);
  }
  getBulkAttendanceReport(reportId,staffAgency,staffSchool,startDate,endDate){
    const url = `${environment.reportsUrl}attendance-report/${reportId}/${staffAgency}/${staffSchool}/${startDate}/${endDate}`
    console.log("Attendance report URL: ",url);
    return this.httpClient.get(url);
  }
  //Directly calls the Early Release Forms report generation method 
  // getERFormReport(school,date,agency){
  //   const url = `${environment.reportsUrl}GenerateERFormReport/${agency}/${school}/${date}`
  //   console.log("Generate ER Form Report: ",url);
  //   return this.httpClient.get(url);
  // }
  getERFormReport(agency,school,date){
    const url = `${environment.reportsUrl}attendance-er-form-report/${agency}/${school}/?date=${date}`
    console.log("Generate ER Form Report: ",url);
    return this.httpClient.get(url);
  }
  getReportDates(agencyId: any, id: any, name:any) {
    if(name.includes("Early Release Forms")){
      const url = `${environment.attendanceReportsUrl}ERreport-filters?schoolId=${id}&agencyId=${agencyId}`
    return this.httpClient.get(url);

    }
    else{
    const url = `${environment.attendanceReportsUrl}report-filters?schoolId=${id}&agencyId=${agencyId}`
    return this.httpClient.get(url);
  }
  }

  getCurrentReconDate()
  {
    const url = `${environment.reportsUrl}getCurrentReconDate`
    return this.httpClient.get(url);
  }
  getAttendanceAuditReports()
  {
    const url = `${environment.reportsUrl}student-attendance-audit-report-list`
    return this.httpClient.get(url);
  }
  getStudentAttendanceAuditReport(requestReport):Observable<any> {
         return this.httpClient.post(environment.reportsUrl + 'student-attendance-audit-report',
          requestReport,{ responseType: 'text' });
  }
  AddS3ReportUrl(reportId, reportUrl){
     this.S3Reports[reportId] = reportUrl;
  }

  RemoveS3ReportUrl(reportId){
    this.S3Reports[reportId] = null;
 }
}
