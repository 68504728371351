// used for student landing page
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { calendar } from "ngx-bootstrap/chronos/moment/calendar";

@Injectable({
  providedIn: 'root'
})
export class SchoolToStudentsService {

  public idToken: string;

  constructor(public httpClient: HttpClient, public storage: Storage) { }
  // fetch schools
  // getSchools() {
  //   console.log('getSchools()'); // todo: comment out logging as needed for prod
  //   return this.loadSchools().pipe(map(this.processSchools, this));
  // }

  // private loadSchools() {
  //   console.log('loadSchools() with url: ', environment.schoolsUrl);
  //   return this.httpClient.get(environment.schoolsUrl);
  // }

  processSchools(data: any) {
    console.log('processSchools() with', data.length, 'rows.');

    // generate the cds7Code from last 7 digits of the cdsCode.
    let cds7CodeString = '';
    data.forEach((row: any) => {
      if (row.cdsCode) {
        const cdsCodeString = row.cdsCode.toString();
        cds7CodeString = cdsCodeString.slice(cdsCodeString.length - 7);
        row.cds7Code = parseInt(cds7CodeString, 10);
      }
    });

    return data;
  }
  // fetch students by school ID and agency
  getStudentsByAgency(schoolId: number, criteria) {
    console.log('getStudentsByAgency(schoolId)'); // todo: comment out logging as needed for prod
    return this.loadStudentsByAgency(schoolId, criteria).pipe(map(this.processStudents, this));
  }
  getStudentsByAgencyByCalendarYr(schoolId: number, criteria, yearId) {
    console.log('getStudentsByAgency(schoolId)'); // todo: comment out logging as needed for prod
    return this.loadStudentsByAgencyCalendarYear(schoolId, criteria,yearId).pipe(map(this.processStudents, this));
  }
  getStudentsByAgencyByCalendarYrAndType(schoolId: number, criteria, yearId, type) {
    console.log('getStudentsByAgencyByCalendarYrAndType(schoolId)'); // todo: comment out logging as needed for prod
    return this.loadStudentsByAgencyCalendarYearAndType(schoolId, criteria,yearId,type).pipe(map(this.processStudents, this));
  }
  getStudentsByAgencyByMasterCalendar(schoolId: number, criteria, mastercalendar) {
    console.log('getStudentsByAgencyByMasterCalendar(schoolId)'); // todo: comment out logging as needed for prod
    return this.loadStudentsByAgencyMasterCalendar(schoolId, criteria,mastercalendar).pipe(map(this.processStudents, this));
  }

  getStudentsBySearchCriteria(studentsSearchRequest) {
    console.log('getStudentsByAgencyByMasterCalendar(schoolId)'); // todo: comment out logging as needed for prod
    return this.loadStudentsBySearchCriteria(studentsSearchRequest);
  }

  private loadStudentsBySearchCriteria(studentsSearchRequest) {
    const agencyid = this.profile.agency;
    studentsSearchRequest.agencyId = agencyid;
    var url = environment.schoolStudentUrl + 'getstudentsbysearchcriteria';
    console.log('loadStudentsBySearchCriteria() with url: ', url);
    // const header = new HttpHeaders().set(
    //   'Authorization',
    //   'Bearer ' + this.idToken
    // );
    return this.httpClient.post(url, studentsSearchRequest);
  }


  getStudentsByAgencyBySchool(schoolId: number, agencyId: number) {
    console.log('getStudentsByAgencyBySchool()'); // todo: comment out logging as needed for prod
    return this.loadStudentsByAgencyBySchool(schoolId, agencyId).pipe(map(this.processStudents, this));
  }
  getStudentsByAgencyBySchoolbyCalYr(schoolId: number, agencyId: number, type: number, year: number) {
    console.log('getStudentsByAgencyBySchoolbyCalenderYear()'); // todo: comment out logging as needed for prod
    return this.loadStudentsByAgencyBySchoolbyCalYr(schoolId, agencyId, type, year).pipe(map(this.processStudents, this));
  }
  getStudentsByAgencyBySchoolbyMasterCalendar(schoolId: number, agencyId: number, masterCalendar: string) {
    console.log('getStudentsByAgencyBySchoolbyCalenderYear()'); // todo: comment out logging as needed for prod
    return this.loadStudentsByAgencyBySchoolbyMasterCalendar(schoolId, agencyId, masterCalendar).pipe(map(this.processStudents, this));
  }

  getWaitListStudentsByAgencyBySchool(schoolId: number, agencyId: number) {
    console.log('getWaitListStudentsByAgencyBySchool()',schoolId, agencyId); // todo: comment out logging as needed for prod
    return this.loadWaitListStudentsByAgencyBySchool(schoolId, agencyId).pipe(map(this.processStudents, this));
  }
  // fetch students by school ID
  getStudentsBySchool(schoolId: number) {
    console.log('getStudentsBySchool()'); // todo: comment out logging as needed for prod
    return this.loadStudentsBySchool(schoolId).pipe(map(this.processStudents, this));
  }

  private loadStudentsBySchool(schoolId: number) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsBySchool() with url: ', environment.schoolStudentUrl + '/school/' + schoolId);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.schoolStudentUrl + 'school/' + schoolId, { headers: header });
  }

  private loadStudentsByAgencyBySchool(schoolId: number, agencyId: number) {
    const agencyid = agencyId != null ? agencyId : this.profile.agency;

    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });

    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );

    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [6,7,8];

    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadStudentsByAgencyBySchool() with url: ', environment.schoolStudentUrl + 'agency/' + agencyid + '/location/' + schoolId);
      return this.httpClient.get(environment.schoolStudentUrl + 'agency/' + agencyid + '/location/' + schoolId, { headers: header });
    }
    else {
      console.log('loadStudentsByAgencyBySchool() with url: ', environment.schoolStudentUrl + 'agency/' + agencyid + '/location/' + schoolId);
      return this.httpClient.get(environment.schoolStudentUrl + 'school/' + schoolId, { headers: header });
    }
  }

  private loadStudentsByAgencyBySchoolbyMasterCalendar(schoolId: number, agencyId: number, masterCalendar: string) {
    const agencyid = agencyId != null ? agencyId : this.profile.agency;

    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );

    return this.httpClient.get(environment.schoolStudentUrl + 'agency/' + agencyid + '/location/' + schoolId + '/mastercalendar/' + masterCalendar, { headers: header });
  }

  private loadStudentsByAgencyBySchoolbyCalYr(schoolId: number, agencyId: number, type: number, year: number) {
    const agencyid = agencyId != null ? agencyId : this.profile.agency;

    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );

    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [6,7,8];

    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadStudentsByAgencyBySchoolbyCalYr() with url: ', environment.schoolStudentUrl + 'agency/' + agencyid + '/location/' + schoolId + '/' + type + '/' + year);
      return this.httpClient.get(environment.schoolStudentUrl + 'agency/' + agencyid + '/location/' + schoolId + '/' + type + '/' + year, { headers: header });
    }
    else {
      console.log('loadStudentsByAgencyBySchoolbyCalYr() with url: ', environment.schoolStudentUrl + 'school/' + schoolId + '/' + type + '/' + year);
      return this.httpClient.get(environment.schoolStudentUrl + 'school/' + schoolId + '/' + type + '/' + year, { headers: header });
    }
  }

  private loadWaitListStudentsByAgencyBySchool(schoolId: number, agencyId: number) {
    const agencyid = agencyId != null ? agencyId : this.profile.agency;
    console.log('loadWaitListStudentsByAgencyBySchool() with url: ', environment.schoolStudentUrl + 'waitlist/agency/' + agencyid + '/toschool/' + schoolId);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.schoolStudentUrl + 'waitlist/agency/' + agencyid + '/toschool/' + schoolId, { headers: header });
  }
  private loadStudentsByAgency(schoolId: number, criteria) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsByAgency() with url: ', environment.schoolStudentUrl + 'agency/' +
      agencyid + '/toschool/' + schoolId + '/' + criteria);
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.schoolStudentUrl + 'agency/' + agencyid + '/toschool/' +
      schoolId + '/' + criteria, { headers: header });
  }
  private loadStudentsByAgencyCalendarYear(schoolId: number, criteria, yearId) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsByAgency() with url: ', environment.schoolStudentUrl + 'agency/' +
      agencyid + '/toschool/' + schoolId + '/' + criteria+'/'+yearId);
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.schoolStudentUrl + 'agency/' + agencyid + '/toschool/' +
      schoolId + '/' + criteria+'/'+yearId, { headers: header });
  }
  private loadStudentsByAgencyCalendarYearAndType(schoolId: number, criteria, yearId, type) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsByAgency() with url: ', environment.schoolStudentUrl + 'agency/' +
      agencyid + '/toschool/' + schoolId + '/' + criteria+'/'+yearId+'/'+type);
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.schoolStudentUrl + 'agency/' + agencyid + '/toschool/' +
      schoolId + '/' + criteria + '/' + yearId +'/' + type, { headers: header });
  }

  private loadStudentsByAgencyMasterCalendar(schoolId: number, criteria, mastercalendarcode) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsByAgencyMasterCalendar() with url: ', environment.schoolStudentUrl + 'agency/' +
      agencyid + '/toschool/' + schoolId + '/' + criteria+'/mastercalendar/'+ mastercalendarcode);
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.schoolStudentUrl + 'agency/' + agencyid + '/toschool/' + schoolId + '/' + criteria + '/mastercalendar/'+ mastercalendarcode
    , { headers: header });
  }

  processStudents(data: any) {
    return data;
  }
  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }
}
