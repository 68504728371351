import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Components } from "@ionic/core";
import { ReportsService } from "../reports/reports.service";
import { environment } from "../../environments/environment";
import * as moment from "moment";
import { AgenciesService } from "../agencies/agencies.service";
//import { saveAs } from 'file-saver';
import { DatePipe } from "@angular/common";

import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { AwardsService } from "../awards/awards.service";
import { SchoolsService } from "../schools/schools.service";
import { ThrowStmt } from "@angular/compiler";
import { ElementArrayFinder } from "protractor";
import { elementEventFullName } from "@angular/compiler/src/view_compiler/view_compiler";
import { FundingService } from "../funding/funding.service";
import { CalendarService } from "./../core/services/calendar.service";
import { GlobalShareService } from "../core/services/global-share.service";
import { DatepickerDateCustomClasses } from "ngx-bootstrap/datepicker";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "generate-reports",
  templateUrl: "./generate-reports.component.html",
  styleUrls: ["./generate-reports.component.scss"],
})
export class GenerateReportsComponent implements OnInit {
  public reportForm: FormGroup;
  loading = false;
  @Input() create;
  //yearList = new Date().getFullYear();
  Reports: any;
  @Input() modal: Components.IonModal;
  isConfirm = false;
  @Input() isCancel;
  showRadioOption = true;
  blob: any;
  reportS3url: any;
  generateReportText: any = "Generate Report";
  generatedReportName: any;
  report: any;
  agenciesList: any = [];
  yearOpt: any = 0;
  agencyId: any = -1;
  awardsList: any = [];
  funderList: any = [];
  schoolsList: any = [];
  absenceRosterStudentSelection:String="all";
  enrichmentCodesList: any = [];
  calendarYearList: any = [];
  activitiesList: any = [];
  reportName: any;
  showAgency: boolean;
  showFYCYOpt: boolean;
  showSchools: boolean;
  showYear: boolean;
  showMCYear: boolean;
  expandFYDP: boolean;
  showCYYear: boolean;
  showAnnualOutcome: boolean;
  fiscalYearList: any[];
  masterCalendarList: any[];
  tempActivitiesList: any[];
  showStart: boolean;
  showAwards: boolean;
  showEnd: boolean;
  hideAgency: boolean = true;
  disableGenerate = false;
  disableStartEnd = true;
  disableSchools = false;
  disableAgency = false;
  disableEnrichmentCodes = false;
  disableActivities = false;
  showEnrichmentCodes: boolean;
  disableAwards = false;
  multipleAgencies = false;
  multipleActivities = true;
  start: any = null;
  displayMultipleSelection: boolean = false;
  displayMultipleSchoolSelection: boolean = false;
  isSchoolsListUpdated: boolean = false;
  // minDate: Date = null;
  // maxDate: Date = null;

  startMinDate: Date = null;
  startMaxDate: Date = new Date();

  endMinDate: Date = null;
  endMaxDate: Date = null;

  allDates: any = [];
  enabledDates: any = [];
  highlightOnlyReportDates: DatepickerDateCustomClasses[] = [];
  end: any = null;
  fyear: any;
  reportstatus: any;
  selectedAgencyId: any = -1;
  selectedMCC: any;
  selectAgencyFormControl = new FormControl();
  searchAgencyInputTxt = new FormControl();
  selectedAgency = [];
  filteredAgencyOptions: Observable<any[]>;

  selectSchoolFormControl = new FormControl();
  searchSchoolInputTxt = new FormControl();
  selectedSchool = [];
  filteredSchoolOptions: Observable<any[]>;
  selectedAgencyIds: any;
  selectedSchoolIds: any;

  @ViewChild("searchAgency", { static: false }) searchAgencyTextBox: ElementRef;
  @ViewChild("searchSchool", { static: false }) searchSchoolTextBox: ElementRef;

  // s3reports:Array<any> = [];
  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    private reportService: ReportsService,
    public agenciesService: AgenciesService,
    public awardsService: AwardsService,
    public schoolsService: SchoolsService,
    public fundingService: FundingService,
    public calendarService: CalendarService,
    public toastController: ToastController,
    private globalShareService: GlobalShareService,
    private cdr:ChangeDetectorRef
  ) {
    this.reportForm = formBuilder.group({
      agencies: [[], [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      masterCalendarId: ["0", Validators.required],
      fiscalYearId: [0, Validators.required],
    });
  }
  private _agencyFilter(name: string): String[] {
    const filterValue = name.toLowerCase();
    this.setAgencySelectedValues();
    this.selectAgencyFormControl.patchValue(this.selectedAgency);
    let filteredList = this.agenciesList.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
    filteredList.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    var allAgencies = "All Agencies";
    filteredList.unshift(
      filteredList.splice(
        filteredList.findIndex((item) => item.name === allAgencies),
        1
      )[0]
    );
    return filteredList;
  }
  private _schoolFilter(name: string): String[] {
    const filterValue = name.toLowerCase();
    this.setSchoolSelectedValues();
    this.selectSchoolFormControl.patchValue(this.selectedSchool);
    let filteredList = this.schoolsList.filter(
      (option) => option.schoolName.toLowerCase().indexOf(filterValue) === 0
    );
    filteredList.sort((a, b) =>
      a.schoolName.toLowerCase() > b.schoolName.toLowerCase() ? 1 : -1
    );
    var allschools = "All Schools";
    filteredList.unshift(
      filteredList.splice(
        filteredList.findIndex((item) => item.schoolName === allschools),
        1
      )[0]
    );
    console.log(
      "This is the filtered list: -------------------?" + filteredList
    );
    return filteredList;
  }
  setAgencySelectedValues() {
    if (
      this.selectAgencyFormControl.value &&
      this.selectAgencyFormControl.value.length > 0
    ) {
      this.selectAgencyFormControl.value.forEach((e) => {
        if (this.selectedAgency.indexOf(e) == -1) {
          this.selectedAgency.push(e);
        }
      });
    }
  }
  setSchoolSelectedValues() {
    if (
      this.selectSchoolFormControl.value &&
      this.selectSchoolFormControl.value.length > 0
    ) {
      this.selectSchoolFormControl.value.forEach((e) => {
        if (this.selectedSchool.indexOf(e) == -1) {
          this.selectedSchool.push(e);
        }
      });
    }
  }
  selectionAgencyChange(event) {
    console.log("Selection agency change called");
    this.selectedSchool = [];
    this.selectSchoolFormControl.patchValue(this.selectedSchool);
    if (event.isUserInput) {
      if (event.source.selected == true) {
        event.source.value === "All Agencies"
          ? (this.selectedAgency = this.agenciesList.map((x) => {
              return x.shortCode;
            }))
          : this.selectedAgency.push(event.source.value);
      } else {
        if (event.source.value === "All Agencies") {
          this.selectedAgency = [];
        } else {
          let index = this.selectedAgency.indexOf(event.source.value);
          this.selectedAgency.splice(index, 1);
          let allindex = this.selectedAgency.indexOf("All Agencies");
          this.selectAgencyFormControl.value.includes("All Agencies")
            ? this.selectedAgency.splice(allindex, 1)
            : "";
        }
      }
      this.selectAgencyFormControl.patchValue(this.selectedAgency);
      this.onAgencyChangeMultiSelect(event);
    }
  }
  onStudentTypeChange(event: any) {
    this.absenceRosterStudentSelection=event.detail.value;
    console.log('Student Type changed to:', this.absenceRosterStudentSelection);
    console.log('Event Value:', event.detail.value);
  }

  selectionSchoolChange(event) {
    // this.selectedSchool = [];
    if (event.isUserInput) {
      if (event.source.selected == true && !this.isSchoolsListUpdated) {
        this.isSchoolsListUpdated = true;
        if (event.source.value === "All Schools") {
          this.selectedSchool = this.schoolsList.map((x) => x.schoolName);
        } else {
          if (!this.selectedSchool.includes(event.source.value)) {
            this.selectedSchool = [...this.selectedSchool, event.source.value];
          }
        }
        this.isSchoolsListUpdated = false;
        /*  event.source.value == "All Schools"
          ? (this.selectedSchool = this.schoolsList.map((x) => {
              return x.schoolName;
            }))
          : this.selectedSchool.push(event.source.value);*/
      } else {
        console.log("Line number 240");
        if (event.source.value == "All Schools") {
          this.selectedSchool = [];
        } else {
          console.log("Line number 244");
          let index = this.selectedSchool.indexOf(event.source.value);
          this.selectedSchool.splice(index, 1);
          let allindex = this.selectedSchool.indexOf("All Schools");
          this.selectSchoolFormControl.value.includes("All Schools")
            ? this.selectedSchool.splice(allindex, 1)
            : "";
        }
      }
      this.selectSchoolFormControl.patchValue(this.selectedSchool);
      this.onSchoolChangeMultiSelect(event);
    }
  }


  // fetch reports data
  fetchData() {
    //called when clicking on a specific report
    this.loading = true;
    this.reportService.getReports().subscribe((data: any) => {
      console.log("fetchData(): data from service:", data);
      this.Reports = data;
    });
  }
  // fetch award data by school ID
  fetchAwards(id) {
    this.disableAwards = false;
    this.awardsService.getAwardsBySchoolId(id).subscribe((data: any) => {
      console.log("fetchAwards(): data from agencies service:", data);
      this.awardsList = [];
      data = Array.from(new Set(data.map((a) => a.award.id))).map((id) => {
        return data.find((a) => a.award.id === id);
      });
      data.forEach((element) => {
        const dooType = element.award.dooType === 1 ? "CORE" : "SUPP";
        const type =
          element.award.type === 1 ? "Before School" : "After School";
        const funder = element.award.fundingSource.funder
          ? element.award.fundingSource.funder.shortCode
          : "N/A";
        element.award.text =
          funder +
          " - " +
          element.award.fundingSource.cohort +
          " - " +
          dooType +
          " - " +
          type;
        if (element.award.isActive) {
          this.awardsList.push(element.award);
        }
      });
      this.disableAwards = this.awardsList.length > 0 ? false : true;
    });
  }
  // fetch schools data by Agency ID
  fetchSchools(id) {
    this.schoolsList = [];
    this.disableSchools = false;
    console.log("report.reportName ", this.report.reportName);

    if (
      !this.report.reportName.includes("Global Report") &&!this.hasRoles([7,8])
      // this.report.reportName.includes("Performance Summary") ||
      // this.report.reportName.includes("Attendance Summary") ||
      // this.report.reportName.includes("Raw Attendance Report") ||
      // this.report.reportName.includes("Raw Attendance Report")
    ) {
      let selectedAgencyIds =
        this.displayMultipleSelection == true
          ? this.agenciesList
              .filter((x) =>
                this.selectAgencyFormControl.value.includes(x.shortCode)
              )
              .map((x) => {
                return x.id;
              })
          : id;

      selectedAgencyIds =
        this.displayMultipleSelection == true
          ? selectedAgencyIds
              .map((e) => e.toString().replace(/\s/g, ""))
              .join(",")
          : id;


      this.schoolsService
        .getSchoolsByAgencyIds(selectedAgencyIds)
        .subscribe((data: any) => {
          // this.schoolsList = data;
          this.schoolsList = data.sort((a, b) =>
            a.schoolName.localeCompare(b.schoolName)
          );

          if (this.displayMultipleSchoolSelection) {
            this.schoolsList.unshift({ id: -1, schoolName: "All Schools" });
            this.reportForm.get("schoolId").setValue(-1);
            let schoolEvent = {
              isUserInput: true,
              source: { selected: true, value: "All Schools" },
            };
          }
          //this.selectionSchoolChange(schoolEvent);
          this.disableSchools = this.schoolsList.length > 0 ? false : true;
          this.filteredSchoolOptions =
            this.searchSchoolInputTxt.valueChanges.pipe(
              startWith<string>(""),
              map((name) => this._schoolFilter(name))
            );
        });
    } else {
      this.schoolsService.getSchoolsByAgency(id).subscribe((data: any) => {
        console.log("fetchAgencyList(): data from agencies service:", data);
        // this.schoolsList = data;
        this.schoolsList = data.sort((a, b) =>
          a.schoolName.localeCompare(b.schoolName)
        );
        if (
          (this.report.reportName.includes("Participation") ||
          this.report.reportName.includes("Unduplicated Student Names") ||
          this.report.reportName.includes("Attendance Summary") ||
          this.report.reportName.includes("Student Enrollment Report")||
          this.report.reportName.includes("Offline Attendance") ||
          this.report.reportName.includes("Raw Attendance Report"))
            // && !this.hasRoles([7, 8])
        ) {
          this.schoolsList.unshift({ id: -1, schoolName: "All Schools" });
          this.reportForm.get("schoolId").setValue(-1);
        }

        this.disableSchools = this.schoolsList.length > 0 ? false : true;
      });
    }
  }
  // fetch schools data
  fetchAllSchools() {
    this.schoolsList = [];
    this.disableSchools = false;
    this.schoolsList = [...this.globalShareService.allSchools];
    this.disableSchools = this.schoolsList.length > 0 ? false : true;
  }
  //fetch schools data by Agency ID and Master Calendar Code
  fetchSchoolsMCC() {
    this.schoolsList = [];
    var currentAgencyId =
      this.selectedAgencyId == -1 ? this.agencyId : this.selectedAgencyId;
    this.disableSchools = false;
    console.log("currentAgencyId :", currentAgencyId);
    this.schoolsService
      .getSchoolsByAgencyMC(currentAgencyId, this.selectedMCC)
      .subscribe((data: any) => {
        console.log("fetchSchoolsMCC(): data from schools service:", data);
        this.schoolsList = data;
        this.schoolsList.unshift({ id: -1, schoolName: "All Schools" });
        this.reportForm.get("schoolId").setValue(-1);
        this.selectedSchool = this.schoolsList.map((x) => {
          return x.schoolName;
        });
        this.selectSchoolFormControl.patchValue(this.selectedSchool);

        this.disableSchools = this.schoolsList.length > 0 ? false : true;
        this.filteredSchoolOptions =
          this.searchSchoolInputTxt.valueChanges.pipe(
            startWith<string>(""),
            map((name) => this._schoolFilter(name))
          );
      });
  }

  fetchEnrichmentCode() {
    this.disableEnrichmentCodes = false;
    this.calendarService.getEnrichmentCodes().subscribe((data: any) => {
      console.log("getEnrichmentCodes(): data :", data);
      this.enrichmentCodesList = data;
      this.disableEnrichmentCodes =
        this.enrichmentCodesList.length > 0 ? false : true;
    });
  }
  // fetch agency data
  fetchAgencyList() {
    if(this.hasRoles([1,2,3,4,5,6]) && (this.report.reportName.includes("Raw Attendance Report"))
      || (this.report.reportName.includes("Daily Attendance Backup")  ||
    this.report.reportName.includes('Absence Roster')||
    this.report.reportName.includes('Early Release Forms'))){
      this.displayMultipleSelection = false;
    }

    else{
      this.displayMultipleSelection = true;

    }
    if(this.hasRoles([1,2,3,4,5,6]) && (this.report.reportName.includes("Offline Attendance") ||
    (this.report.reportName.includes("Attendance Summary")||
    this.report.reportName.includes("Student Enrollment Report") )
      || (this.report.reportName.includes("Performance Summary")) )){
      this.displayMultipleSchoolSelection = true;
      this.displayMultipleSelection = true;
    }
    else{

      this.displayMultipleSchoolSelection = false;
      this.displayMultipleSelection = false;
    }
    this.agenciesService.getAgencies().subscribe((data: any) => {
      console.log("fetchAgencyList(): data from agencies service:", data);
      this.agenciesList = data
        .filter((x) => x.isActive)
        .sort((a, b) => a.name.localeCompare(b.name));

      if (
        this.report.reportName.includes("Grant Export") ||
        this.report.reportName.includes("Performance Summary")
      ) {
        this.agenciesList.unshift({
          id: -1,
          name: "All Agencies",
          shortCode: "All Agencies",
        });
        this.reportForm.get("agencies").setValue([-1]);
        let agencyEvent = {
          isUserInput: true,
          source: { selected: true, value: "All Agencies" },
        };
        this.selectionAgencyChange(agencyEvent);
      }
      else if ((this.report.reportName.includes('Raw Attendance Report') ||   this.report.reportName.includes('Offline Attendance')  || this.report.reportName.includes('Absence Roster') || this.report.reportName.includes('Early Release Forms')||
        this.report.reportName.includes('Attendance Summary')||
        this.report.reportName.includes("Student Enrollment Report")) && this.hasRoles([6, 7, 8])) {
        this.disableAgency = true;
        const agencies = [];
        this.displayMultipleSelection = false;
        agencies.push(this.profile.agency);
        this.reportForm.get("agencies").patchValue(agencies);

        if (
          this.report.reportName.includes("Raw Attendance Report") ||
          this.report.reportName.includes("Offline Attendance") || this.report.reportName.includes('Absence Roster')
        ) {
          this.fetchSchools(this.profile.agency);
        }
      } else if (
        this.report.reportName.includes("Attendance Summary") ||
        this.report.reportName.includes("Offline Attendance")||
        this.report.reportName.includes("Student Enrollment Report")

      ) {
        this.agenciesList.unshift({
          id: -1,
          name: "All Agencies",
          shortCode: "All Agencies",
        });
        let agencyEvent = {
          isUserInput: true,
          source: { selected: true, value: "All Agencies" },
        };
        this.selectionAgencyChange(agencyEvent);
        this.reportForm.get("agencies").setValue([-1]);
      }
      console.log("this.agenciesList", this.agenciesList);
      this.filteredAgencyOptions = this.searchAgencyInputTxt.valueChanges.pipe(
        startWith<string>(""),
        map((name) => this._agencyFilter(name))
      );
    });
  }
  openedAgencyChange(e) {
    this.searchAgencyInputTxt.patchValue("");
    if (e == true) {
      this.searchAgencyTextBox.nativeElement.focus();
    }
  }
  openedSchoolChange(e) {
    this.searchSchoolInputTxt.patchValue("");
    if (e == true) {
      this.searchSchoolTextBox.nativeElement.focus();
    }
  }
  clearAgencySearch(event) {
    event.stopPropagation();
    this.searchAgencyInputTxt.patchValue("");
  }
  clearSchoolSearch(event) {
    event.stopPropagation();
    this.searchSchoolInputTxt.patchValue("");
  }
  // fetch fiscal years data
  fetchFiscalYear() {
    this.reportService.getFiscalYears().subscribe((data: any) => {
      this.fiscalYearList = data.filter((x) => x.isActive);
      console.log(
        "generatereportpopup:fetchFiscalYear(): data from fiscalYear service:",
        this.fiscalYearList
      );
      if (this.report.reportName.includes("Projection")) {
        const currentFiscalYear = this.fiscalYearList.find((x) => x.isCurrent);
        this.reportForm.get("fiscalYearId").setValue(currentFiscalYear.id);
        this.reportForm.get("fiscalYearId").disable();
        this.reportForm.get("startDate").disable();
        this.reportForm.get("endDate").disable();
      }
    });
  }
  // fetch master calendars data
  fetchMasterCalendars() {
    this.reportService.getMasterCalendarsForReports().subscribe((data: any) => {
      this.masterCalendarList = data.filter(x => x.isActive);
      console.log('generatereportpopup:fetchFiscalYear(): data from fiscalYear service:', this.fiscalYearList);
      if (this.report.reportName.includes('Projection')) {
        const currentMasterCalendar = this.masterCalendarList.find(x => x.isCurrent);
        this.reportForm.get('masterCalendarId').setValue(currentMasterCalendar.id);
        this.reportForm.get('masterCalendarId').disable();
        this.reportForm.get('startDate').disable();
        this.reportForm.get('endDate').disable();
      }
    });
  }
  // fetch activities list by agency ID

  fetchActivities(schoolId) {
    this.disableActivities = false;
    if (this.displayMultipleSelection) {
      this.selectedSchoolIds = this.schoolsList
        .filter((x) =>
          this.selectSchoolFormControl.value.includes(x.schoolName)
        )
        .map((x) => {
          return x.id;
        });

      this.selectedSchoolIds = this.selectedSchoolIds
        .map((e) => e.toString().replace(/\s/g, ""))
        .join(",");

      this.selectedAgencyIds = this.agenciesList
        .filter((x) => this.selectAgencyFormControl.value.includes(x.shortCode))
        .map((x) => {
          return x.id;
        });

      this.selectedAgencyIds = this.selectedAgencyIds
        .map((e) => e.toString().replace(/\s/g, ""))
        .join(",");

      if (
        this.selectedSchoolIds.split(",").length > 1 ||
        this.selectedAgencyIds.split(",").length > 1
      ) {
        this.disableActivities = true;
        return;
      }
    }
    else if((this.report.reportName==='Offline Attendance'||
      this.report.reportName==='Attendance Summary'||
      this.report.reportName.includes("Student Enrollment Report") ) && this.hasRoles([6])){

      this.selectedSchoolIds = this.schoolsList
        .filter((x) =>
          this.selectSchoolFormControl.value.includes(x.schoolName)
        )
        .map((x) => {
          return x.id;
        });


      this.selectedAgencyIds = this.profile.agency;


      if (
        this.selectedSchoolIds.split(",").length > 1 ||
        this.selectedAgencyIds.split(",").length > 1
      ) {
        this.disableActivities = true;
        return;
      }

    }

    else {

      if((this.report.reportName==='Absence Roster' || this.report.reportName==='Offline Attendance'  || this.report.reportName==='Early Release Forms') && this.hasRoles([6,7,8])){

        this.selectedSchoolIds = schoolId;
        this.selectedAgencyId = this.profile.agency;
        this.selectedAgencyIds = this.profile.agency;

        console.log("profile. agency id is   ",this.profile.agency)
        console.log("this.agencyid agency id is   ",this.agencyId)
      }else{

        this.selectedSchoolIds = schoolId;
        this.selectedAgencyIds = this.selectedAgencyId;
      }
    }

    console.log("selectedSchoolIds", this.selectedSchoolIds);
    console.log("selectedAgencyIds", this.selectedAgencyIds);

    var agencyId = this.selectedAgencyId;
    var masterCalendarCode = this.selectedMCC;
    if (this.selectedMCC) {
      this.agenciesService
        .getActivities(
          this.selectedAgencyIds,
          this.selectedSchoolIds,
          masterCalendarCode
        )
        .subscribe((data: any) => {
          console.log("this.activitiesList", data);
          this.activitiesList = data.map((item) => ({
            activityId: item.scheduledActivity.id,
            activityName:
              item.activityName + " - " + item.scheduledActivity.groupName,
          }));
        });
    }
  }

  // fetch CY funders data
  fetchFunders() {
    this.fundingService.getFundingCy().subscribe((data: any) => {
      console.log("fetchFunders(): data from funding service:", data);
      data.forEach((x) => {
        x.name = x.fundingSource.funder.name;
        x.id = x.fundingSource.funder.id;
      });
      this.funderList = data.filter(
        (x) =>
          x.fundingSource.funder.name === "ASSETS" ||
          x.fundingSource.funder.name === "ASES" ||
          x.fundingSource.funder.name === "21CCLC"
      );
    });
  }
  // fetch CY data
  fetchCalendarYears() {
    this.reportService.getCalendarYears().subscribe((data: any) => {
      console.log("fetchCalendarYears(): data from fiscalYear service:", data);
      this.calendarYearList = data.filter((x) => x.isActive);
    });
  }
  // sets start and end date range once user inputs fiscal year
  onFYChange(event) {
    this.disableStartEnd = false;
    this.start = null;
    this.end = null;
    this.startMinDate = moment(event[0].data.fromDate).toDate();
    this.startMaxDate = moment(event[0].data.toDate).toDate();
    this.endMinDate = this.startMinDate;
    this.endMaxDate = this.startMaxDate;
    this.start = moment(event[0].data.fromDate).format("MM/DD/YYYY");
    this.end = moment(event[0].data.toDate).format("MM/DD/YYYY");
    this.reportForm.get("startDate").setValue(this.start);
    this.reportForm.get("endDate").setValue(this.end);
  }
  // sets start and end date range once user inputs calendar year
  onCYChange(event) {
    this.disableStartEnd = false;
    this.start = null;
    this.end = null;
    // this.minDate = moment(event[0].data.fromDate).toDate();
    // this.maxDate = moment(event[0].data.toDate).toDate();

    this.startMinDate = moment(event[0].data.fromDate).toDate();
    this.startMaxDate = moment(event[0].data.toDate).toDate();
    this.endMinDate = this.startMinDate;
    this.endMaxDate = this.startMaxDate;

    this.start = moment(event[0].data.fromDate).format("MM/DD/YYYY");
    this.end = moment(event[0].data.toDate).format("MM/DD/YYYY");
    this.reportForm.get("startDate").setValue(this.start);
    this.reportForm.get("endDate").setValue(this.end);
  }
  onMCChange(event) {
    this.selectedMCC = event[0].data.masterCalendarCode;
    this.disableStartEnd = false;
    this.start = null;
    this.end = null;
    this.startMinDate = moment("01/01/1000").toDate();
    this.startMaxDate = moment().toDate();
    this.endMinDate = this.startMinDate;
    this.endMaxDate = this.startMaxDate;
    this.start = moment(event[0].data.fromDate).format("MM/DD/YYYY");
    this.end = moment(event[0].data.toDate).format("MM/DD/YYYY");
    this.reportForm.get("startDate").setValue(this.start);
    if(!this.report.reportName.includes("Student Enrollment Report")){
    this.reportForm.get("endDate").setValue(this.end);
  }
    const agencyId = document.getElementById("");
    if (this.hasRoles([6, 7, 8])) {
      this.fetchSchools(this.profile.agency);
    }
    else {
      if(!this.report.reportName.includes('Absence Roster'))
     
      this.fetchSchools(this.profile.agency);
    }
  }
  // collects agencies selected
  onAgencyChangeMultiSelect(selectedEvent) {
    let agency = this.agenciesList.filter(
      (x) => x.shortCode === selectedEvent.source.value
    );
    let event = [{ data: { id: agency[0].id } }];
    const agencies = [];
    event.forEach((element) => {
      agencies.push(element.data.id);
    });
    // if report selected in 'Grant Export', user can only choose 1 agency
    if (this.report.reportName.includes("Grant Export")) {
      if (agencies.includes(-1)) {
        this.multipleAgencies = false;
        this.reportForm.get("agencies").setValue([-1]);
      } else {
        this.multipleAgencies = true;
      }
    }
    if (this.report.reportName.includes("Daily Attendance Backup")
      || this.report.reportName.includes('Early Release Forms')||
      this.report.reportName.includes('Absence Roster')) {
      this.multipleAgencies = false;
      this.selectedAgencyId = event[0].data.id;
      this.disableSchools = true;
      if (event.length === 0) {
        this.schoolsList = [];
      }
      if (event.length === 1) {
        this.fetchSchools(event[0].data.id);
      }
      return;
    }
    if (
      this.displaySchools() &&
      this.hasRoles([1, 2, 3, 4, 5]) &&
      !this.report.reportName.includes("Attendance Summary")
    ) {
      this.disableSchools = true;
      if (event.length === 0) {
        this.schoolsList = [];
      }
      if (event.length === 1) {
        this.fetchSchools(event[0].data.id);
      }
      if (event.length > 1) {
        this.schoolsList.unshift({ id: -1, schoolName: "All Schools" });
        this.reportForm.get("schoolId").setValue(-1);
        this.selectedSchool = this.schoolsList.map((x) => {
          return x.schoolName;
        });
        this.selectSchoolFormControl.patchValue(this.selectedSchool);
        this.disableSchools = true;
      }
    }
    this.reportForm.get("agencies").patchValue(agencies);
    if (
      (this.report.reportName.includes("Attendance Summary") ||
      this.report.reportName.includes("Absence Roster")||
      this.report.reportName.includes("Student Enrollment Report")) &&
      !this.report.reportName.includes("Provider")
    ) {
      this.selectedAgencyId = event[0].data.id;
      this.fetchMasterCalendars();
    }
  }
  // collects agencies selected
  onAgencyChange(event) {
    const agencies = [];
    event.forEach((element) => {
      agencies.push(element.data.id);
    });
    // if report selected in 'Grant Export', user can only choose 1 agency
    if (this.report.reportName.includes("Grant Export")) {
      if (agencies.includes(-1)) {
        this.multipleAgencies = false;
        this.reportForm.get("agencies").setValue([-1]);
      } else {
        this.multipleAgencies = true;
      }
    }

    if (this.report.reportName.includes("Daily Attendance Backup")
    || this.report.reportName.includes('Early Release Forms')
    ) {
      this.multipleAgencies = false;
      this.selectedAgencyId = event[0].data.id;
      this.disableSchools = true;
      if (event.length === 0) {
        this.schoolsList = [];
      }
      if (event.length === 1) {
        this.fetchSchools(event[0].data.id);
      }
      return;
    }
    if (
      this.displaySchools() &&
      this.hasRoles([1, 2, 3, 4, 5]) &&
      !this.report.reportName.includes("Attendance Summary")
    ) {
      this.disableSchools = true;
      if (event.length === 0) {
        this.schoolsList = [];
      }
      if (event.length === 1) {
        this.fetchSchools(event[0].data.id);
      }
      if (event.length > 1) {
        this.schoolsList.unshift({ id: -1, schoolName: "All Schools" });
        this.reportForm.get("schoolId").setValue(-1);
        this.selectedSchool = this.schoolsList.map((x) => {
          return x.schoolName;
        });
        this.selectSchoolFormControl.patchValue(this.selectedSchool);
        this.disableSchools = true;
      }
    }
    this.reportForm.get("agencies").patchValue(agencies);
    if (
      (this.report.reportName.includes("Attendance Summary") || this.report.reportName.includes("Absence Roster")||
      this.report.reportName.includes("Student Enrollment Report")) &&
      !this.report.reportName.includes("Provider")
    ) {
      this.selectedAgencyId = event[0].data.id;
      this.fetchMasterCalendars();
    }
  }

  onSchoolChangeMultiSelect(selectedEvent) {
    var name=this.report.reportName;
    let school = this.schoolsList.filter(
      (x) => x.schoolName === selectedEvent.source.value
    );
    let event = [{ data: { id: school[0].id } }];

    this.enabledDates = this.allDates;
    this.highlightOnlyReportDates = [];
    if (this.displayAwards()) {
      this.disableAwards = true;
      this.fetchAwards(event[0].data.id);
    }
    const requestReport = this.reportForm.value;
    if (this.hasRoles([6, 7, 8])) {
      if (this.agencyId && event[0].data.id) {
        var currentAgencyId =
          this.selectedAgencyId == -1 ? this.agencyId : this.selectedAgencyId;

        this.reportService
          .getReportDates(currentAgencyId, event[0].data.id,name)
          .subscribe((dates) => {
            this.availableDate = dates;

            this.enableReportAvailableDates(this.availableDate);
          });
      }
    } else {
      if (requestReport.agencies && event[0].data.id) {
        this.reportService
          .getReportDates(requestReport.agencies, event[0].data.id,name)
          .subscribe((dates) => {
            this.availableDate = dates;
            this.enableReportAvailableDates(this.availableDate);
          });
      }
    }
    this.fetchActivities(event[0].data.id);
  }

  onSchoolChange(event) {
    var name=this.report.reportName;
    this.enabledDates = this.allDates;
    this.highlightOnlyReportDates = [];
    if (this.displayAwards()) {
      this.disableAwards = true;
      this.fetchAwards(event[0].data.id);
    }
    const requestReport = this.reportForm.value;
    if (this.hasRoles([6, 7, 8])) {
      if (this.agencyId && event[0].data.id) {
        var currentAgencyId =
          this.selectedAgencyId == -1 ? this.agencyId : this.selectedAgencyId;
        this.reportService

          .getReportDates(currentAgencyId, event[0].data.id,name)
          .subscribe((dates) => {
            this.availableDate = dates;

            this.enableReportAvailableDates(this.availableDate);
          });
      }
    } else {
      if (requestReport.agencies && event[0].data.id) {
        this.reportService
          .getReportDates(requestReport.agencies, event[0].data.id,name)
          .subscribe((dates) => {
            this.availableDate = dates;
            this.enableReportAvailableDates(this.availableDate);
          });
      }
    }
    console.log("event[0].data", event[0].data.id);
    this.fetchActivities(event[0].data.id);
  }
  onActivityChange(event) {
    this.tempActivitiesList = [];
    event.forEach((element) => {
      this.tempActivitiesList.push(element.data.activityId);
    });
  }

  availableDate: any = [];

  enableReportAvailableDates(reportAvailableDate) {
    if (this.report.reportName.includes("Daily Attendance Backup") || this.report.reportName.includes("Early Release Forms") ) {
      this.enabledDates = reportAvailableDate.map((f) =>
        Date.parse(f.to_char + " 23:59:59")
      );
      let reportDates = reportAvailableDate.map((f) => f.to_char + " 23:59:59");
      reportDates.forEach((date) => {
        let highlightedTo = {
          date: new Date(date),
          classes: ["highlight-reportdate"],
        };
        this.highlightOnlyReportDates.push(highlightedTo);
      });
    }
  }

  // fetches user's profile details
  get profile() {
    return JSON.parse(
      window.localStorage.getItem("_ionicstorage/_ionickv/userSubject")
    );
  }

  highlightReportDate(date, mode) {
    if (mode === "day") {
      var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
      var currentDay = new Date().setHours(0, 0, 0, 0);

      if (dayToCheck === currentDay) {
        return "highlight-date";
      }
    }

    return "";
  }

  // limits user's permissions based on roles
  hasRoles(roles: number[]): boolean {
    if (this.profile) {
      for (const aRole of this.profile.roles) {
        if (roles.includes(aRole)) {
          return true;
        }
      }
    }
    return false;
  }

  displayAnnualOutcome() {
    this.showAnnualOutcome = this.report.reportName.includes("Annual Outcome");
    return this.showAnnualOutcome;
  }

  displayAgency() {
    this.showAgency =
      this.report.reportName.includes("Attendance Summary") ||
      this.report.reportName.includes("Student Enrollment Report")||
      this.report.reportName.includes("Offline Attendance") ||
      this.report.reportName.includes("Performance Summary") ||
      this.report.reportName.includes("Raw Attendance Report") ||
     this.report.reportName.includes('Early Release Forms')||
     this.report.reportName.includes('Absence Roster')||
      (this.report.reportName.includes("Daily Attendance Backup")  &&
        !this.hasRoles([6, 7, 8]))
        ? true
        : false;

    return this.showAgency;
  }

  displayFYCYOpt() {
    this.showFYCYOpt =
      (this.report.reportName.includes("Attendance Summary")||
      this.report.reportName.includes("Student Enrollment Report")) &&
      this.report.reportName.includes("Provider");
    return this.showFYCYOpt;
  }

  displaySchools() {
    this.showSchools =
      ((this.report.reportName.includes('Attendance Summary') && !this.report.reportName.includes('BTB Attendance Summary (by Provider) [report]'))
        || this.report.reportName.includes('Offline Attendance')
        || this.report.reportName.includes('Early Release Forms')
        || this.report.reportName.includes('Absence Roster')
        ||this.report.reportName.includes("Student Enrollment Report")
        || this.report.reportName.includes('Performance Summary')
        || this.report.reportName.includes('Raw Attendance Report')
        || this.report.reportName.includes('Daily Attendance Backup') ) ? true : false;
    return this.showSchools;
  }
  displayActivities() {
    if (
      (this.report.reportName.includes("Attendance Summary")
      ||this.report.reportName.includes("Student Enrollment Report")) &&
      !this.report.reportName.includes("(by Provider)") &&
      !this.report.reportName.includes(
        "Total Daily Attendance Per Site by Enrichment Code"
      )
    ) {
      return true;
    }
    return false;
  }

  doOpen() {
    this.expandFYDP = true;
    console.log("SingleDemoComponent.doOpen");
  }
  doClose() {
    this.expandFYDP = false;
    console.log("SingleDemoComponent.doClose");
  }
  displayYear() {
    if (this.yearOpt === 1) {
      this.showYear = true;
    } else {
      this.showYear = this.report.reportName.includes("Global Report")
        ? true
        : false;
    }
    return this.showYear;
  }
  displayMCYear() {
    return (
      (this.report.reportName.includes("Attendance Summary")||
      this.report.reportName.includes('Absence Roster')||
      this.report.reportName.includes("Student Enrollment Report")) &&
      !this.report.reportName.includes(
        "BTB Attendance Summary (by Provider) [report]"
      )
    );
    //this.showMCYear = this.report.reportName.includes('Attendance Summary') && !this.report.ReportName.includes('Provider') ? true : false;
    //return this.showMCYear;
  }
  displayCYYear() {
    if (this.yearOpt === 2) {
      this.showCYYear = true;
      return this.showCYYear;
    }
    this.showCYYear = this.report.reportName.includes("Progress By Grant Type")
      ? true
      : false;
    return this.showCYYear;
  }
  displayAwards() {
    this.showAwards = this.report.reportName.includes("Progress By Grant Type")
      ? true
      : false;
    return this.showAwards;
  }
  displayStart() {
    this.showStart = this.report.reportName.includes('Activity') || (this.report.reportName.includes('HS'))
      || this.report.reportName.includes('Dosage') || this.report.reportName.includes('Progress By Grant Type')
      || this.report.reportName.includes('Projection')
      || this.report.reportName.includes('Participation')
      || this.report.reportName.includes('Daily Attendance Backup')
      ||this.report.reportName.includes("Student Enrollment Report")
      || this.report.reportName.includes('Early Release Forms')
      || this.report.reportName.includes('Absence Roster')
      || this.report.reportName.includes('Raw Attendance Report')
      || this.report.reportName.includes('Offline Attendance')
      || this.report.reportName.includes('Summary')
      || this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code')
      || this.report.reportName.includes('Unduplicated Student Names') ? true : false;
    return this.showStart;
  }
  displayEnd() {
    this.showEnd =
      this.report.reportName.includes("Activity") ||
      this.report.reportName.includes("HS") ||
      this.report.reportName.includes("Dosage")||
      this.report.reportName.includes("Student Enrollment Report") ||
      this.report.reportName.includes("Progress By Grant Type") ||
      this.report.reportName.includes("Participation") ||
      this.report.reportName.includes("Raw Attendance Report")||
      this.report.reportName.includes("Offline Attendance") ||
      this.report.reportName.includes("Projection") ||
      this.report.reportName.includes("Summary") ||
      this.report.reportName.includes(
        "Total Daily Attendance Per Site by Enrichment Code"
      ) ||
      this.report.reportName.includes("Unduplicated Student Names")
        ? true
        : false;
    return this.showEnd;
  }
  displayEnrichmentCodes() {
    this.showEnrichmentCodes = this.report.reportName.includes(
      "Total Daily Attendance Per Site by Enrichment Code"
    )
      ? true
      : false;
    return this.showEnrichmentCodes;
  }
  selectionChanged(event) {
    console.log(event);
    event = event.sort((a, b) => (a.value > b.value ? 1 : -1));
  }

  yearOptionChange(event) {
    if (event.detail.value === 1) {
      this.fetchFiscalYear();
      this.yearOpt = 1;
    } else {
      this.fetchCalendarYears();
      this.yearOpt = 2;
    }
  }

  cancelReports() {
    this.modal.dismiss("cancel");
    // this.modal.dismiss({ 'cancel': true });
  }

  reportMsg = "";
  downloadS3() {
    this.reportMsg = "";
    const requestReport = this.reportForm.value;
    const pipe = new DatePipe("en-US");
    const startDate = pipe.transform(requestReport.startDate, "yyyy-MM-dd");
    var findReport = this.availableDate.find((a) => a.to_char == startDate);
    if (findReport) {
      let agency = requestReport.agencies;
      let schoolId = requestReport.schoolId;
      if (this.hasRoles([6, 7, 8])) {
        agency = this.agencyId;
      }
      this.reportService
        .getAttendanceReport(agency, requestReport.schoolId, startDate)
        .subscribe((response: any) => {
          window.open(response.download);
        });
    } else {
      this.reportMsg = "No report available for this date";
    }
  }

  changeStartDate(event) {
    if (event == "Invalid Date") {
      this.reportForm.get("startDate").setValue("");
    } else if (event != "") {
      console.log(event);
      let inputDay = new Date(event);
      if (inputDay > this.startMaxDate) {
        this.reportForm.get("startDate").setValue(this.startMaxDate);
        return;
      }
      if (inputDay < this.startMinDate) {
        this.reportForm.get("startDate").setValue(this.startMinDate);
        return;
      }
      if (
        this.report.reportName.includes("Raw Attendance") ||
        this.report.reportName.includes("Offline Attendance")||
        this.report.reportName.includes("Absence Roster")
      ) {
        this.endMinDate = new Date(event);
        this.endMaxDate = new Date(event);
        this.endMaxDate.setDate(this.endMaxDate.getDate() + 60);

        let today = new Date();
        let tempEndDate = this.endMaxDate <= today ? this.endMaxDate : today;
        this.endMaxDate = tempEndDate;

        this.reportForm.get("endDate").setValue(this.endMaxDate);
      } else if(this.report.reportName.includes("Student Enrollment Report") ){
        this.endMinDate = new Date(event);
        this.endMaxDate = new Date(event);
        this.endMaxDate.setDate(this.endMaxDate.getDate() + 366);
        let today = new Date();
        let tempEndDate = this.endMaxDate <= today ? this.endMaxDate : today;
        this.endMaxDate = tempEndDate;
      }
      else if (this.report.reportName.includes("Performance Summary")
      ) {
        this.endMinDate = new Date(event);
      }
    }
  }

  changeEndDate(event) {
    let inputDay = new Date(event);
    if (inputDay > this.endMaxDate) {
      this.reportForm.get("endDate").setValue(this.endMaxDate);
      return;
    }
    if (inputDay < this.endMinDate) {
      this.reportForm.get("endDate").setValue(this.endMinDate);
      return;
    }
    console.log(event);
    if (this.report.reportName.includes("Performance Summary")
    ) {
      this.startMaxDate = new Date(event);
    }
  }
  generateERReport(){
    console.log("Erreport generate called")
    this.reportMsg = ''
    const requestReport = this.reportForm.value;
    const pipe = new DatePipe('en-US');
    const startDate = pipe.transform(requestReport.startDate, 'yyyy-MM-dd');
    var findReport = this.availableDate.find((a) => a.to_char == startDate);
    if (findReport) {
    let agency = requestReport.agencies;
    let schoolId = requestReport.schoolId
    if (this.hasRoles([6, 7, 8])) {
      agency = this.agencyId;
    }
    this.reportService.getERFormReport(agency, requestReport.schoolId, startDate).subscribe((response: any) => {
      window.open(response.download);
    });
  }else{
    this.reportMsg="No report available for this date";
    }

    return;
  }
  generateReports(reportToBuild) {
    console.log("Generate Reports called");

    if (reportToBuild.reportName == 'Daily Attendance Backup') {
      // this.generateERReport();
      this.downloadS3();

      return;}
    if (reportToBuild.reportName == 'Early Release Forms') {

      console.log("Early Release Forms report calling")
      this.generateERReport();
      return;}


    console.log("this.displayMultipleSelection", this.displayMultipleSelection);
    console.log("this.report.reportname", this.report.reportname);
    this.reportstatus =
      "Report can be downloaded once its generated and ready for download.";
    let reporturl = this.reportService.getS3ReportUrl(reportToBuild.id);

    if (reporturl === null || reporturl === undefined || true) {
      const pipe = new DatePipe("en-US");
      this.disableGenerate = true;
      this.generateReportText = "Generating...";
      const requestReport = this.reportForm.value;
if((this.report.reportName==="Absence Roster" || this.report.reportName==="Early Release Forms") && (this.hasRoles([6, 7, 8]))){

  requestReport.schools = this.displayMultipleSelection
          ? this.schoolsList
              .filter((x) =>
                this.selectSchoolFormControl.value.includes(x.schoolName)
              )
              .map((x) => {
                return x.id;
              })
          : [this.selectedSchoolIds];
          requestReport.agencies = this.displayMultipleSelection
          ? this.agenciesList
              .filter((x) =>
                this.selectAgencyFormControl.value.includes(x.shortCode)
              )
              .map((x) => {
                return x.id;
              })
          : this.profile.agency;

        console.log("requestReport.agencie" + requestReport.agencies);
        console.log("requestReport.schools" + requestReport.schools);
}
      if (
        this.report.reportName === "Performance Summary" ||
        this.report.reportName === "Attendance Summary" ||this.report.reportName.includes("Student Enrollment Report")||

        this.report.reportName === "Offline Attendance"
      ) {

        if(  (this.report.reportName === "Attendance Summary" ||this.report.reportName.includes("Student Enrollment Report")||

          this.report.reportName === "Offline Attendance" )&&this.hasRoles([7,8])){
            requestReport.schools = this.displayMultipleSelection
          ? this.schoolsList
              .filter((x) =>
                this.selectSchoolFormControl.value.includes(x.schoolName)
              )
              .map((x) => {
                return x.id;
              })
          : [this.selectedSchoolIds];
        requestReport.agencies = this.displayMultipleSelection
          ? this.agenciesList
              .filter((x) =>
                this.selectAgencyFormControl.value.includes(x.shortCode)
              )
              .map((x) => {
                return x.id;
              })
          : this.selectedAgencyIds;

        console.log("requestReport.agencie" + requestReport.agencies);
        console.log("requestReport.schools" + requestReport.schools);
          }

        else{
        requestReport.schools = this.displayMultipleSelection
          ? this.schoolsList
              .filter((x) =>
                this.selectSchoolFormControl.value.includes(x.schoolName)
              )
              .map((x) => {
                return x.id;
              })
          : this.selectedSchoolIds;
        requestReport.agencies = this.displayMultipleSelection
          ? this.agenciesList
              .filter((x) =>
                this.selectAgencyFormControl.value.includes(x.shortCode)
              )
              .map((x) => {
                return x.id;
              })
          : this.selectedAgencyIds;

        console.log("requestReport.agencie" + requestReport.agencies);
        console.log("requestReport.schools" + requestReport.schools);
      }
      } else {
        if (requestReport.schoolId !== undefined) {
          if (
            requestReport.schoolId === -1 &&
            !this.report.reportName.includes("Attendance Summary") &&
            !this.report.reportName.includes("Raw Attendance Report")
          ) {
            requestReport.schools = [];
          } else {
            requestReport.schools = [requestReport.schoolId];
          }
          delete requestReport.schoolId;
        } else {
          requestReport.schools = [];
        }
        if (requestReport.agencies && requestReport.agencies.length > 0) {
          if (requestReport.agencies.includes(-1)) {
            requestReport.agencies = [];
          }
        }
      }

      requestReport.activities = this.tempActivitiesList;
      if (requestReport.activities === undefined) {
        requestReport.activities = [];
      }
      requestReport.reportId = reportToBuild.id;
      requestReport.category = this.report.category;
      requestReport.reportName = this.report.reportName;
      requestReport.agencies = this.showAgency
        ? requestReport.agencies instanceof Array == true
          ? requestReport.agencies
          : [requestReport.agencies]
        : [environment.agencyId];
      requestReport.fiscalYearId = this.showYear
        ? requestReport.fiscalYearId
        : null;
      let startDate = null;
      let endDate = null;

      console.log("requestReport.agencie" + requestReport.agencies);
      console.log("requestReport.schools" + requestReport.schools);
      if (requestReport.startDate != null) {
        startDate = pipe.transform(requestReport.startDate, "yyyy-MM-dd");
      }

      if (requestReport.endDate) {
        endDate = pipe.transform(requestReport.endDate, "yyyy-MM-dd");
      }

      this.generatedReportName = reportToBuild.reportName;
      requestReport.startDate = startDate;
      requestReport.endDate = endDate;

      /*`if (moment(requestReport.startDate).isAfter(moment(requestReport.endDate))) {
        this.throwValidationError('Start Date cannot be after End Date');
        this.disableGenerate = false;
        this.generateReportText = 'Generate Report';
      } else {
        this.reportService.generateGlobalReport(requestReport).subscribe((res) => {
          // console.log('Response:', res);
          this.isConfirm = true;
          this.blob = new Blob([res], { type: res.type });
        },
          error => {
            // console.log('Error:', error);
            this.disableGenerate = false;
            this.generateReportText = 'Generate Report';
            this.isConfirm = false;
          });
      }*/

      if (
        moment(requestReport.startDate).isAfter(moment(requestReport.endDate))
      ) {
        this.throwValidationError("Start Date cannot be after End Date");
        this.disableGenerate = false;
        this.generateReportText = "Generate Report";
      } else {
        console.log("submitReportRequest:", requestReport);
        this.reportService.submitReportRequest(requestReport,this.absenceRosterStudentSelection).subscribe(
          (res) => {
            // console.log('Response:', res);
            // this.isConfirm = true;
            // this.blob = new Blob([res], { type: res.type });
            this.reportService.AddS3ReportUrl(
              requestReport.reportId,
              res.reportUrl
            );
            this.modal.dismiss("submitted");
          },
          (error) => {
            // console.log('Error:', error);
            this.disableGenerate = false;
            this.generateReportText = "Generate Report";
            this.isConfirm = false;
          }
        );
      }
    } else {
      // this.isConfirm = true;
    }
  }

  async throwValidationError(message: string) {
    const toast = await this.toastController.create({
      color: "danger",
      duration: 2500,
      message: message,
      showCloseButton: true,
      position: "top",
    });
    await toast.present();
  }

  downloadFile(reportId) {
    let reporturl = this.reportService.getS3ReportUrl(reportId);
    console.log("downloadFile for report:", reportId, reporturl);

    let reportName = reporturl.split("/")[4].split(".")[0];
    this.reportService.reportRequestComplete(reportName).subscribe(
      (res) => {
        console.log("downloadReport: Response:", res);
        // this.isConfirm = true;
        // this.blob = new Blob([res], { type: res.type });
        this.modal.dismiss("submitted");
        this.blob = "";
        this.showSuccess();
        this.generatedReportName = "";
        window.location.href = reporturl;
      },
      (error) => {
        console.log("downloadReport : Error:", error);
        this.disableGenerate = true;
        this.generateReportText = "Generating ...";
        // this.isConfirm = true;
        this.reportstatus =
          "Report generation is in progress.... Please try later or contact support team.";
      }
    );
    // saveAs(this.blob, this.generatedReportName + '.xlsx');
  }

  confirmationBox() {
    // this.isConfirm = true;
  }

  async showSuccess() {
    const toast = await this.toastController.create({
      color: "success",
      duration: 2500,
      message: "Success!",
      showCloseButton: true,
      position: "top",
    });
    await toast.present();
  }

  async showError(error) {
    const toast = await this.toastController.create({
      color: "danger",
      duration: 2500,
      message: "Error!",
      showCloseButton: true,
      position: "top",
    });
    await toast.present();
  }

  isSelected(selectedAgencyOption: any): boolean {
    console.log(selectedAgencyOption);
    return this.selectedAgency.some(
      (agency) => agency.id === selectedAgencyOption.id
    );
  }

  onAgencyCheckboxChange(event: Event, onAgencyChange: any): void {
    const checkedAgency = event.target as HTMLInputElement;
    if (checkedAgency.checked) {
      if (!this.isSelected(onAgencyChange)) {
        this.selectedAgency.push(onAgencyChange);
      }
    } else {
      this.selectedAgency = this.selectedAgency.filter(
        (agency) => agency.id !== onAgencyChange.id
      );
    }
    console.log(this.selectedAgency);
  }

  ngOnInit() {
    this.reportstatus =
      "Report can be downloaded once its generated and ready for download.";

    let reporturl = this.reportService.getS3ReportUrl(this.report.id);
    console.log("generateReports(): ", this.report.id, reporturl);
    if (reporturl === null || reporturl === undefined) {
      this.fetchData();
      this.reportForm.statusChanges.subscribe((change) => {
        if (this.reportForm.dirty) {
          this.modal.backdropDismiss = false;
        }
      });
      this.agencyId = this.profile.agency;
      if (this.displayAnnualOutcome()) {
        this.reportForm.addControl(
          "funderId",
          new FormControl("", Validators.required)
        );
        this.reportForm.get("agencies").setValue([this.agencyId]);
        this.reportForm.get("fiscalYearId").setValue(null);
        this.reportForm.removeControl("startDate");
        this.reportForm.removeControl("endDate");
        this.fetchFunders();
      }
      if (
        this.report.reportName.includes("Raw Attendance") ||
        this.report.reportName.includes("Offline Attendance")||
        this.report.reportName.includes("Performance Summary")
        ||this.report.reportName.includes("Student Enrollment Report")||
        this.report.reportName.includes('Absence Roster')
      ) {
        this.disableStartEnd = false;
      };
      if (this.report.reportName.includes("Performance Summary")) {
        this.reportForm.get("agencies").setValue([-1]);
      }
      if (this.report.reportName.includes("Projection")) {
        if (this.agencyId === null) {
          this.reportForm.removeControl("agencies");
        } else {
          this.reportForm.get("agencies").setValue([this.agencyId]);
        }
      }
      if (this.report.reportName.includes("Activity")) {
        this.disableStartEnd = false;
        if (this.agencyId) {
          this.reportForm.get("agencies").setValue([this.agencyId]);
        } else {
          this.reportForm.removeControl("agencies");
        }
      }
      if (this.report.reportName.includes("Global Report")) {
        this.reportForm.get("fiscalYearId").setValue(null);
        this.reportForm.removeControl("agencies");
        this.reportForm.removeControl("startDate");
        this.reportForm.removeControl("endDate");
      }
      if (this.report.reportName.includes("Progress By Grant Type")) {
        this.disableAwards = true;
        this.reportForm.removeControl("agencies");
        if (this.hasRoles([6, 7])) {
          this.fetchSchools(this.agencyId);
        } else {
          this.fetchAllSchools();
        }
      }
      if (
        this.report.reportName.includes(
          "Total Daily Attendance Per Site by Enrichment Code"
        )
      ) {
        this.reportForm.removeControl("agencies");
        this.disableEnrichmentCodes = true;
        this.fetchEnrichmentCode();
      }
      if (this.displayAwards()) {
        this.reportForm.addControl(
          "awardId",
          new FormControl("", Validators.required)
        );
      }
      if (this.hasRoles([6, 7])) {
        if (
          !this.report.reportName.includes("Global Report") &&
          !this.report.reportName.includes("Progress By Grant Type") &&
          !this.report.reportName.includes(
            "Total Daily Attendance Per Site by Enrichment Code"
          )
        ) {
          this.reportForm.get("agencies").setValue([this.agencyId]);
        }
      }
      if (this.displayAgency()) {
        this.fetchAgencyList();
        if (
          this.report.reportName.includes("Participation") ||
          (this.report.reportName.includes("Attendance Summary")||this.report.reportName.includes("Student Enrollment Report") &&
            this.report.reportName.includes("Provider"))
        ) {
          this.multipleAgencies = true;
          console.log("multipleAgencies :", this.multipleAgencies);
        }
      }
      if (this.displaySchools()) {
        if (this.hasRoles([6, 7])) {
          this.fetchSchools(this.agencyId);
        }
        this.reportForm.addControl(
          "schoolId",
          new FormControl("", Validators.required)
        );
      }
      if (this.displayEnrichmentCodes()) {
        this.reportForm.addControl(
          "enrichmentCode",
          new FormControl("", Validators.required)
        );
      }
      if (this.displayActivities()) {
        this.reportForm.addControl("activities", new FormControl(""));
      }
      if (this.displaySchools() && !this.hasRoles([6, 7])) {
        if (!this.report.reportName.includes("Attendance Summary")||this.report.reportName.includes("Student Enrollment Report")) {
          this.disableSchools = true;
        }
      }
      if (this.displayAwards()) {
        this.reportForm.addControl(
          "awardId",
          new FormControl("", Validators.required)
        );
        this.disableAwards = true;
      }
      if (this.displayYear()) {
        this.fetchFiscalYear();
      }
      if (this.displayCYYear()) {
        this.reportForm.removeControl("fiscalYearId");
        this.reportForm.addControl(
          "calendarYearId",
          new FormControl("", Validators.required)
        );
        this.fetchCalendarYears();
      }
      if (this.report.reportName.includes('Daily Attendance Backup') || this.report.reportName.includes('Early Release Forms')) {
        const start = new Date(2021, 8, 19)
        const end = moment(new Date()).add(-1, 'd').toDate();
        this.disableStartEnd = false;
        // this.minDate = start;
        // this.maxDate = end;

        this.startMinDate = start;
        this.startMaxDate = end;
        this.endMinDate = start;
        this.endMaxDate = end;

        let startDate = this.startMinDate;
        this.allDates.push(this.startMinDate);

        while (startDate <= this.startMaxDate) {
          var nextDate = startDate.setDate(startDate.getDate() + 1);
          this.allDates.push(nextDate);
          startDate = new Date(nextDate);
        }

        if (this.hasRoles([6, 7, 8])) this.reportForm.removeControl("agencies");
        this.reportForm.removeControl("endDate");
        this.reportForm.removeControl("fiscalYearId");
        if (this.hasRoles([6, 7, 8])) {
          this.hideAgency = false;
          this.fetchSchools(this.agencyId);
        }
      }
      if (this.report.reportName.includes("Performance Summary")) {
        this.startMaxDate = new Date();
        this.endMaxDate = new Date();
        /*const start = new Date(2021,8,19)
        const end = moment(new Date()).add(-1,'d').toDate();
        this.disableStartEnd = false;
        // this.minDate = start;
        // this.maxDate = end;

        this.startMinDate = start;
        this.startMaxDate = end;
        this.endMinDate = start;
        this.endMaxDate = end;

        let startDate = this.startMinDate;
        this.allDates.push(this.startMinDate);

        while(startDate <= this.startMaxDate){
          var nextDate = startDate.setDate(startDate.getDate() + 1);
          this.allDates.push(nextDate);
          startDate = new Date(nextDate);
        }*/
      }
    } else {
      // this.isConfirm = true;
      // this.reportstatus = "Report can be downloaded once its generated and ready for download.";
    }
  }
}


