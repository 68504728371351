import { SupplementalCalendarComponent } from './master-calendar-editor/supplemental-calendar/supplemental-calendar.component';
import { MasterCalendarViewComponent } from './master-calendar-editor/master-calendar-view.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MasterCalendarPageComponent, CalendarException } from './master-calendar-page/master-calendar-page.component';
import { CalendarPeriod } from "./master-calendar-page/CalendarPeriod";
import { ComponentsModule } from './components/components.module';
import { CalendarEditorComponent } from './calendar-editor/calendar-editor.component';
import { BreadCrumbService } from './components/bread-crumb/bread-crumb.service';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MSAdal } from '@ionic-native/ms-adal';
import { AuthServiceProvider } from './users/auth-service/auth-service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { UpdateStudentComponent } from './update-student/update-student.component';
import { UpdateAgencyComponent } from './update-agency/update-agency.component';
import { UpdateAwardComponent } from './update-award/update-award.component';
import { DeactivateAgencyComponent } from './deactivate-agency/deactivate-agency.component';
import { UpdateAuthorizedPersonComponent } from './update-authorized-person/update-authorized-person.component';
import { WithdrawStudentComponent } from './withdraw-student/withdraw-student.component';
import { DeactivateAuthorizedPersonComponent } from './deactivate-authorized-person/deactivate-authorized-person.component';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { UpdateActivityComponent } from './update-activity/update-activity.component';
import { ScheduleActivityComponent } from './schedule-activity/schedule-activity.component';
import { HttpRequestInterceptorService } from './http-request-interceptor.service';
import { UpdateCertificationComponent } from './update-certification/update-certification.component';
import { UpdateFundingComponent } from './update-funding/update-funding.component';
import { UpdateCodeComponent } from './update-enrichmentCode/update-code.component';
import { UpdateAwardAmtComponent } from './update-award-amt/update-award-amt.component';
import { AddAgencyComponent } from './add-agency/add-agency.component';
import { GenerateReportsComponent } from './generate-reports/generate-reports.component';
import { AvailableReportsComponent } from './available-reports/available-reports.component';
import { DeactivateAwardComponent } from './deactivate-award/deactivate-award.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CancelConfirmationComponent } from './cancel-confirmation/cancel-confirmation.component';
import { AssignStudentAgencyComponent } from './assign-student-agency/assign-student-agency.component';
import { RemoveStaffActivityComponent } from './remove-staff-activity/remove-staff-activity.component';
import { PopStudentsAddComponent } from './pop-students-add/pop-students-add.component';
import { PopScheduleActivityComponent } from './pop-schedule-activity/pop-schedule-activity.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UpdateStaffDetailsComponent } from './update-staff-details/update-staff-details.component';
import { ScheduledActivitiesEnrollmentComponent } from './scheduled-activities-enrollment/scheduled-activities-enrollment.component';
import { RouteReuseStrategy } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';
import { SignatureViewerComponent } from './signature-viewer/signature-viewer.component';
import { UserAgencyComponent } from './user-agency/user-agency.component';
import { RemoveStaffCertificationComponent } from './remove-staff-certification/remove-staff-certification.component';
import { AwardDetailsComponent } from './award-details/award-details.component';
import { SchoolDetailsComponent } from './school-details/school-details.component';
import { SchoolDetailsComponent2 } from './funding-details/funding-details.component';
import { PopCertificationTypesComponent } from './pop-certification-types/pop-certification-types.component';
import { UpdateCertificationTypeComponent } from './update-certification-type/update-certification-type.component';
import { PopEnrichmentCodesComponent } from './pop-enrichmentCodes/pop-enrichment-codes.component';
import { StudentAttendanceComponent } from './student-attendance/student-attendance.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { SubmitAttendanceConfirmationComponent } from './submit-attendance-confirmation/submit-attendance-confirmation.component';
import { FilePickerModule } from  'ngx-awesome-uploader';
import { PopRetroActionsComponent } from './pop-retro-actions/pop-retro-actions.component';
import { PopDeleteAttendanceActionsComponent } from './pop-delete-attendance-actions/pop-delete-attendance-actions.component';
import { SystemReconComponent } from './system-recon/system-recon.component';
import { ClipboardModule } from 'ngx-clipboard';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';               
import { BulkReportsComponent } from './bulk-reports/bulk-reports.component';


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSelectModule,
    FormsModule,
    SelectDropDownModule,
    MatSelectModule,
    MatIconModule,
    MatRippleModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxMaskIonicModule.forRoot(),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: ['localstorage', 'websql']
    }),

    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    TimepickerModule.forRoot(),
    NgxDatatableModule,
    ComponentsModule,
    TooltipModule.forRoot(),

    MatExpansionModule,
    FilePickerModule,
    ClipboardModule,
  ],
  declarations: [AppComponent, AuthCallbackComponent, UpdateStudentComponent, UpdateAgencyComponent, UpdateAwardComponent,
    DeactivateAgencyComponent, UpdateCodeComponent,
    UpdateAuthorizedPersonComponent, DeactivateAuthorizedPersonComponent, AddAgencyComponent,
    WithdrawStudentComponent, UpdateActivityComponent, UpdateAwardAmtComponent,BulkReportsComponent,
    ScheduleActivityComponent, UpdateCertificationComponent, UpdateFundingComponent, RemoveStaffActivityComponent,
    GenerateReportsComponent,AvailableReportsComponent, DeactivateAwardComponent, CancelConfirmationComponent, AssignStudentAgencyComponent,
    PopStudentsAddComponent, PopScheduleActivityComponent, UpdateStaffDetailsComponent, ScheduledActivitiesEnrollmentComponent,
    ModalAlertComponent, MasterCalendarPageComponent, CalendarEditorComponent, MasterCalendarViewComponent,
    UserAgencyComponent, PopEnrichmentCodesComponent, SupplementalCalendarComponent, CalendarPeriod, SignatureViewerComponent,
    CalendarException, PopCertificationTypesComponent, UpdateCertificationTypeComponent,
    RemoveStaffCertificationComponent, AwardDetailsComponent, SchoolDetailsComponent2, StudentAttendanceComponent, SubmitAttendanceConfirmationComponent, PopRetroActionsComponent,PopDeleteAttendanceActionsComponent,SystemReconComponent],
  entryComponents: [UpdateStudentComponent, UpdateAgencyComponent, UpdateAwardComponent,
    DeactivateAgencyComponent, AddAgencyComponent, DeactivateAgencyComponent,BulkReportsComponent,
    UpdateAuthorizedPersonComponent, DeactivateAuthorizedPersonComponent, WithdrawStudentComponent, RemoveStaffActivityComponent,
    UpdateActivityComponent, ScheduleActivityComponent, UpdateAwardAmtComponent, UpdateCertificationComponent, UpdateFundingComponent,
    GenerateReportsComponent,AvailableReportsComponent, DeactivateAwardComponent, CancelConfirmationComponent, AssignStudentAgencyComponent,
    ScheduledActivitiesEnrollmentComponent, SignatureViewerComponent, UpdateCodeComponent, PopCertificationTypesComponent,
    UpdateCertificationTypeComponent, PopStudentsAddComponent, PopEnrichmentCodesComponent, PopScheduleActivityComponent, UpdateStaffDetailsComponent,
    UserAgencyComponent, ModalAlertComponent, CalendarPeriod, StudentAttendanceComponent, SubmitAttendanceConfirmationComponent, PopRetroActionsComponent,PopDeleteAttendanceActionsComponent,SystemReconComponent],
  providers: [InAppBrowser, SplashScreen, StatusBar, AuthServiceProvider, BreadCrumbService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptorService,
      multi: true
    },


  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

